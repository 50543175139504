import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const BuyIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 22" fontSize="icon24" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M1.1654 0.999634C0.829998 0.999634 0.643527 1.38761 0.853052 1.64951L4.33315 5.99963V16.9996C4.33315 17.5519 4.78087 17.9996 5.33315 17.9996H19.0116C19.4349 17.9996 19.8124 17.7331 19.954 17.3342L23.8593 6.3342C24.0904 5.68327 23.6077 4.99963 22.917 4.99963H7.16648L4.133 1.35945C3.943 1.13146 3.66156 0.999634 3.36478 0.999634H1.1654ZM18.3332 21.4996C18.3332 22.3281 17.6616 22.9996 16.8332 22.9996C16.0047 22.9996 15.3332 22.3281 15.3332 21.4996C15.3332 20.6712 16.0047 19.9996 16.8332 19.9996C17.6616 19.9996 18.3332 20.6712 18.3332 21.4996ZM7.83316 22.9996C8.66158 22.9996 9.33316 22.3281 9.33316 21.4996C9.33316 20.6712 8.66158 19.9996 7.83316 19.9996C7.00473 19.9996 6.33316 20.6712 6.33316 21.4996C6.33316 22.3281 7.00473 22.9996 7.83316 22.9996Z"
            fill="white"/>

    </SvgIcon>
  )
}

export default BuyIcon24
