import { connect } from 'react-redux'
import { RootState } from 'types/system.types'
import { fetchServersList, getServersList, fetchFarmersList, getFarmersList } from 'modules/materials'
import { getKeysGroupsList } from 'modules/keys'
import { getCampaignsList, fetchCampaignsList } from 'modules/campaigns'
import { createDomains } from 'modules/domains'
import { getUser } from "modules/user"
import DomainsCreateForm from './DomainsCreateForm'


const mapStateToProps = (state: RootState) => {
  return {
    keysGroupsList: getKeysGroupsList(state),
    campaignsList: getCampaignsList(state),
    serversList: getServersList(state),
    farmersList: getFarmersList(state),
    user: getUser(state)
  }
}

const mapDispatchToProps = {
  fetchCampaignsList,
  fetchServersList,
  fetchFarmersList,
  createDomains
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainsCreateForm)
