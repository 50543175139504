import { connect } from 'react-redux'
import { RootState } from 'types/system.types'
import { fetchExpenses, getExpenses } from 'modules/expenses'
import ExpensesPage from './ExpensesPage'
import {getUser} from "modules/user"

const mapStateToProps = (state: RootState) => {
  return {
    expenses: getExpenses(state),
    user: getUser(state)
  }
}

const mapDispatchToProps = {
  fetchExpenses
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpensesPage)
