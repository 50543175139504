import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { multiClientMiddleware } from 'redux-axios-middleware'
import thunk from 'redux-thunk'
import user from 'modules/user'
import notifications from 'modules/notifications'
import keys from 'modules/keys'
import materials from 'modules/materials'
import domains from 'modules/domains'
import campaigns from 'modules/campaigns'
import statistics from 'modules/statistics'
import shells from 'modules/shells'
import expenses from 'modules/expenses'
import apiClients, { clientsOptions } from './api-clients'

const rootReducer = combineReducers({
  user,
  notifications,
  keys,
  materials,
  domains,
  campaigns,
  statistics,
  shells,
  expenses
})

const configureStore = () => {
  return createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(multiClientMiddleware(apiClients, clientsOptions), thunk))
  )
}

export default configureStore
