import { SnackbarKey, OptionsObject } from 'notistack'
import { AxiosDispatch } from 'types/system.types'

export enum NotificationsActionType {
  ADD_NOTIFICATIONS_ITEM = 'ADD_NOTIFICATIONS_ITEM',
  CLOSE_NOTIFICATIONS_ITEM = 'CLOSE_NOTIFICATIONS_ITEM',
  REMOVE_NOTIFICATIONS_ITEM = 'REMOVE_NOTIFICATIONS_ITEM'
}

export interface NotificationsState {
  notificationsList: NotificationsList
}

export interface InfoItem {
  text: string
  value: string | number
}

export interface NotificationsItem {
  key?: SnackbarKey
  title?: string
  info?: string | InfoItem[]
  isAction?: boolean
  isClose?: boolean
  severity?: 'success' | 'error' | 'info' | 'warning'
  dismissAll?: boolean
  dismissed?: boolean
  options?: OptionsObject
}

export type NotificationsList = NotificationsItem[]

export type AddNotificationsItem = (notificationsItem: NotificationsItem) => AddNotificationsItemAction

export interface AddNotificationsItemAction {
  type: NotificationsActionType.ADD_NOTIFICATIONS_ITEM
  payload: NotificationsItem
}

export type AddSuccessNotificationsItem = (props?: {
  title: string
  info: string[]
}) => (dispatch: AxiosDispatch) => void
export type AddErrorNotificationsItem = (code?: string) => (dispatch: AxiosDispatch) => void

export type CloseNotificationsItem = (key: SnackbarKey) => CloseNotificationsItemAction

export interface CloseNotificationsItemAction {
  type: NotificationsActionType.CLOSE_NOTIFICATIONS_ITEM
  payload: NotificationsItem
}

export type RemoveNotificationsItem = (key: SnackbarKey) => RemoveNotificationsItemAction

export interface RemoveNotificationsItemAction {
  type: NotificationsActionType.REMOVE_NOTIFICATIONS_ITEM
  payload: NotificationsItem
}
