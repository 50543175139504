import { ExpensesAction, ExpensesActionType, ExpensesData } from 'types/expenses.types'

export interface ExpensesState {
  data: ExpensesData | null
}

const initialState: ExpensesState = {
  data: null
}

const expensesReducer = (state = initialState, action: ExpensesAction): ExpensesState => {
  switch (action.type) {
    case ExpensesActionType.SET_EXPENSES:
      return {
        ...state,
        data: action.payload
      }
    default:
      return state
  }
}

export default expensesReducer
