import { ExpensesActionType, ExpensesData, SetExpensesAction } from 'types/expenses.types'
import { AxiosDispatch } from 'types/system.types'
import { logError } from 'utils/system.utils'

export const fetchExpenses = (userId: number, dateStart: string, dateEnd: string) => {
  return {
    type: 'FETCH_EXPENSES',
    payload: {
      request: {
        // url: `/materials/domains/spending/${userId}?date_start=${dateStart}&date_final=${dateEnd}`
        url: `https://api.solu.sh/api/v1/materials/domains/spending/${userId}?date_start=${dateStart}&date_final=${dateEnd}`
      },
      options: {
        onSuccess({ dispatch, response }: { dispatch: AxiosDispatch; response: ExpensesData }) {
          dispatch(setExpenses(response))
        },
        onError({ error }: { error: string }) {
          logError(error)
          throw new Error(error)
        }
      }
    }
  }
}

export const setExpenses = (data: ExpensesData): SetExpensesAction => ({
  type: ExpensesActionType.SET_EXPENSES,
  payload: data
})
