import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const ExpensesIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fontSize="icon24" {...props}>
      <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 1H4L1 4.5V9H8V22L11 23L12 22L15 23L16 22L19 23L20 22L22 23V22V4.5L18 1ZM8 5V7H3V5L5 3L8 5ZM10 5.00001H20V6.00001L10 6.00001V5.00001ZM20 7.00001L10 7.00001V8.00001L20 8.00001V7.00001ZM10 9.00001L20 9.00001V10L10 10V9.00001ZM15 19V20H16V19H18L19 17L18 15H16V13H18V12H16V11H15V12H12L11 14L12 16H15V18H12V19H15ZM16 18H17L17.5 17L17 16H16V18ZM15 13H13L12.5 14L13 15H15V13Z"
          fill="currentColor"
      />
    </SvgIcon>
  )
}

export default ExpensesIcon24
