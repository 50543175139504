import {DomainsCreateValues} from "./domains.types";

export enum ExpensesActionType {
  SET_EXPENSES = 'SET_EXPENSES'
}

export interface ZoneItem {
  name: string
  number: number
  cost: number
  spending: number
}

export interface ExpensesSpending {
  sum: number
  fee_trustee: number
  fee_openprovider: number
  sub_openprovider: number
}

export interface ExpensesData {
  zone_list: ZoneItem[]
  spending: ExpensesSpending
  result: number
}

export interface SetExpensesAction {
  type: ExpensesActionType.SET_EXPENSES
  payload: ExpensesData
}

export interface ExpensesState {
  data: ExpensesData
}

export type ExpensesAction = SetExpensesAction

export type ExpensesFetch = (userId: number | null, dateStart: string, dateEnd: string) => void

