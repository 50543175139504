import {object, number, boolean, string} from 'yup'
import { convertStringToNumber, requiredStringSchema } from 'utils/schemas.utils'

export default object().shape({
  cost: number()
    .transform(convertStringToNumber)
    .min(1, 'FORM.FIELD.VALIDATION.ERROR.COST_MIN')
    .required('FORM.FIELD.VALIDATION.ERROR.REQUIRED'),
  accessStatus: boolean(),
  cachedStatus: boolean(),
  comments: string()
})
