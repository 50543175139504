import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const EditIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fontSize="icon24" {...props}>
      <path
          d="M4 17.6669V19.9997H6.33287L16.1626 10.17L13.8297 7.8371L4 17.6669ZM19 7.25287C19.3466 6.90625 19.3466 6.34633 19 5.99971L18 4.99971C17.6534 4.65309 17.0935 4.65309 16.7468 4.99971L15 6.33939L17.6603 8.99971L19 7.25287Z"
          fill="white"/>

    </SvgIcon>
  )
}

export default EditIcon24
