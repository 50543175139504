import React, {FC, useEffect} from 'react'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { IntlShape, useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import FieldTextControlled from 'components/shared/form/FieldTextControlled'
import FieldAutocomplete from 'components/shared/form/FieldAutocomplete'
import FieldNumber from 'components/shared/form/FieldNumber'
import Backdrop from 'components/shared/Backdrop'
import PlusIcon24 from 'components/shared/icons/24x24/PlusIcon24'
import {
  AddShellValues,
  FetchShellsList,
  ShellsFGetStatusItem, ShellsList,
  ShellsTableHeadIds, UpdateShell,
  UpdateShellValues
} from 'types/shells.types'
import { FarmersList } from 'types/materials.types'
import { SortMethod } from 'types/shared.types'
import { PATH_SHELLS } from 'constants/routes.constants'
import {
  SHELLS_CACHE_STATUS_OPTIONS,
  SHELLS_DEFAULT_PAGINATION_PER_PAGE
} from 'constants/shells.constants'
import schema from './EditShellForm.schemas'
import EditIcon24 from "../../../shared/icons/24x24/EditIcon24";


interface AddShellFormParams {
  shellId: number,
  shellsList: ShellsList,
  updateShell: UpdateShell,
  fetchShellsList: FetchShellsList,
  onSuccess: () => void
}

const EditShellForm: FC<AddShellFormParams> = ({shellId, shellsList, updateShell, fetchShellsList, onSuccess  }) => {
  const intl: IntlShape = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const sortMethod = (searchParams.get('sortMethod') as SortMethod) || SortMethod.DESC
  const sortBy = (searchParams.get('sortBy') as ShellsTableHeadIds) || ShellsTableHeadIds.CREATED_AT
  const page = 1
  const perPage = Number(searchParams.get('perPage')) || SHELLS_DEFAULT_PAGINATION_PER_PAGE
  const defaultShell = shellsList.find(shell => shell.id === shellId)

  const addShellValues: UpdateShellValues = {
    cost: 10,
    accessStatus: false,
    cachedStatus: false,
    comments: '',
  }

  const { control, handleSubmit, formState: { isSubmitting }, setValue } = useForm({
    defaultValues: {
      cost: defaultShell?.cost ?? 0,
      accessStatus: defaultShell?.accessStatus ?? false,
      cachedStatus: defaultShell?.cachedStatus ?? false,
      comments: defaultShell?.comments ?? '',
    },
    mode: 'all',
    resolver: yupResolver(schema)
  })

  const handleUpdateShell = async (values: UpdateShellValues) => {
    try {
      await updateShell(values, shellId)

      handleFetchShellsList()
      onSuccess()
    } catch (error) {
      console.log(error)
    }
  }

  const handleFetchShellsList = () => {
    fetchShellsList(sortMethod, sortBy, page, perPage)

    navigate({
      pathname: PATH_SHELLS,
      search: createSearchParams({ sortMethod, sortBy, page: String(page), perPage: String(perPage) }).toString()
    })
  }

  return (
    <form onSubmit={handleSubmit(handleUpdateShell)}>
      <FieldNumber
        sx={{ mb: 2 }}
        control={control}
        name="cost"
        label="FORM.FIELD.TITLE.COST"
        numberProps={{ decimalScale: 2 }}
      />

      <FieldAutocomplete
          sx={{ mb: 2 }}
          label="FORM.FIELD.TITLE.CACHE"
          name="cachedStatus"
          control={control}
          optionsList={SHELLS_CACHE_STATUS_OPTIONS}
          getValue={value => SHELLS_CACHE_STATUS_OPTIONS.find(option => option.value === value)}
          getNewValue={(newValue: ShellsFGetStatusItem | null) => newValue?.value || false}
          getOptionLabel={option => (option ? intl.formatMessage({ id: option?.title }) : '')}
          renderOption={(props, { title }) => (
              <MenuItem {...props} key={title}>
                {intl.formatMessage({ id: title })}
              </MenuItem>
          )}
      />

      <FieldAutocomplete
          sx={{ mb: 2 }}
          label="FORM.FIELD.TITLE.ACCESS"
          name="accessStatus"
          control={control}
          optionsList={SHELLS_CACHE_STATUS_OPTIONS}
          getValue={value => SHELLS_CACHE_STATUS_OPTIONS.find(option => option.value === value)}
          getNewValue={(newValue: ShellsFGetStatusItem | null) => newValue?.value || false}
          getOptionLabel={option => (option ? intl.formatMessage({ id: option?.title }) : '')}
          renderOption={(props, { title }) => (
              <MenuItem {...props} key={title}>
                {intl.formatMessage({ id: title })}
              </MenuItem>
          )}
      />

      <FieldTextControlled sx={{ mb: 2 }} label="FORM.FIELD.TITLE.COMMENTS" name="comments" control={control} />

      <Box display="flex" justifyContent="center" mt={4}>
        <Button type="submit" variant="contained" size="large" fullWidth startIcon={<EditIcon24 />}>
          {intl.formatMessage({ id: 'SHELLS_PAGE.BUTTON.UPDATE_SHELL' })}
        </Button>
      </Box>
      <Backdrop open={isSubmitting} />
    </form>
  )
}

export default EditShellForm
