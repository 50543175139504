import { connect } from 'react-redux'
import EditShellForm from './EditShellForm'
import {RootState} from "types/system.types"
import {fetchShellsList, getShellsList, updateShell} from 'modules/shells'

const mapStateToProps = (state: RootState) => {
  return {
    shellsList: getShellsList(state)
  }
}

const mapDispatchToProps = {
  updateShell,
  fetchShellsList
}

export default connect(mapStateToProps, mapDispatchToProps)(EditShellForm)
