import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { createSearchParams, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import AddIcon16 from "../icons/16x16/AddIcon16";
import useMediaQuery from '@mui/material/useMediaQuery'
import InProgressIcon24 from "../icons/24x24/InProgressIcon24";
import SelectIcon24 from "../icons/24x24/SelectIcon24";
import BuyIcon24 from "../icons/24x24/BuyIcon24";
import {CalendarIcon} from "@mui/x-date-pickers";
import ClockIcon24 from "../icons/24x24/ClockIcon24";
interface FiltersProps {
  domainStatistics: any,
  onClearDomains: () => void,
  onApplyFilters: (params: {
    sortMethod: string;
    sortBy: string;
    page: number;
    perPage: number;
    dataMin?: number;
    dataMax?: number;
    status?: string[];
  }) => void;
}

const Chip = ({ label }: { label: string }) => {
  return (
      <Box
          sx={{
            ml: 1,
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '43',
            height: '20',
            borderRadius: '6px',
            paddingTop: '2px',
            paddingRight: '6px',
            paddingBottom: '2px',
            paddingLeft: '6px',
            gap: '4px',
            background: '#FFFFFF12',
            fontFamily: 'Inter',
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '0%',
          }}
      >
        {label}
      </Box>
  )
}

const Filters2: React.FC<FiltersProps> = ({ onClearDomains, onApplyFilters, domainStatistics }) => {
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) || 1;
  const perPage = Number(searchParams.get('perPage')) || 25;
  const sortMethod = searchParams.get('sortMethod') || 'desc';
  const sortBy = searchParams.get('sortBy') || 'created_at';
  const minValue = 1;
  const maxValue = 100;
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const currentStatus = searchParams.get('status');

  const handleApplyFilters = (status: string[]) => {
    const params = {
      sortMethod: sortMethod,
      sortBy: sortBy,
      page: page,
      perPage: perPage,
      dataMin: minValue !== undefined ? minValue : 1,
      dataMax: maxValue !== undefined ? maxValue : 100,
      status: status,
    };

    onApplyFilters(sortMethod, sortBy, page, perPage, minValue, maxValue, status)
    onClearDomains()
    navigate(
        {
          pathname,
          search: createSearchParams({ ...searchParams, ...params }).toString(),
          hash,
        },
        { replace: true }
    );
  };
  const cssTabs = {
    height: '48px',
    padding: '12px 24px',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: 'normal',
    textDecoration: 'none',
    marginLeft: '16px',
    borderColor: "transparent",
    "&:hover": {
      borderColor: "transparent",

    },
    "&.active": {
      border: 'solid 1px transparent',
      background: '#ffffff0a',
    },
  }
  return (
      <Box display="flex" width="100%" sx={{
        flexDirection: isSmallScreen ? 'column' : 'row',
        alignItems: isSmallScreen ? 'flex-start' : 'flex-start'
      }}>
        <Button className={currentStatus === 'verified' ? 'active' : ''} sx={{
            ...cssTabs,
          }}  onClick={() => handleApplyFilters(['verified'])} variant="outlined" color="secondary" size="small"  fullWidth={true}>
          <SelectIcon24 sx={{ mr: 1}}/> Перевірені
        </Button>
        <Button className={currentStatus === 'buying' ? 'active' : ''} sx={{
          ...cssTabs
        }} onClick={() => handleApplyFilters(['buying'])} variant="outlined" color="secondary" size="small"  fullWidth={true}>
          <ClockIcon24 sx={{ mr: 1}}/> Купуються
          <Box sx={{ display: 'flex', gap: 1 }}>
            {domainStatistics.zone?.buying &&
                Object.entries(domainStatistics.zone.buying).map(([zone, count]) => (
                    <Chip key={zone} label={`.${zone} ${count}`} />
                ))}
          </Box>
        </Button>
        <Button className={currentStatus === 'bought' ? 'active' : ''} sx={{
          ...cssTabs
        }} onClick={() => handleApplyFilters(['bought'])} variant="outlined" color="secondary" size="small"  fullWidth={true}>
          <BuyIcon24 sx={{ mr: 1}}/> Куплені

          <Box sx={{ display: 'flex', gap: 1 }}>
            {domainStatistics.zone?.bought &&
                Object.entries(domainStatistics.zone.bought).map(([zone, count]) => (
                    <Chip key={zone} label={`.${zone} ${count}`} />
                ))}
          </Box>
        </Button>

      </Box>
  );
};

export default Filters2;
