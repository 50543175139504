import {
  PATH_STATISTICS,
  PATH_KEYS,
  PATH_DOMAINS,
  PATH_SHELLS,
  PATH_CAMPAIGNS,
  PATH_STATISTICS_DEFAULT,
  PATH_DOMAINS_ACTIVE_DEFAULT,
  PATH_KEYS_DEFAULT,
  PATH_SHELLS_DEFAULT, PATH_EXPENSES
} from 'constants/routes.constants'
import StatisticsIcon24 from 'components/shared/icons/24x24/StatisticsIcon24'
import KeyIcon24 from 'components/shared/icons/24x24/KeyIcon24'
import DomainsIcon24 from 'components/shared/icons/24x24/DomainsIcon24'
import ShellsIcon24 from 'components/shared/icons/24x24/ShellsIcon24'
import CampaignsIcon24 from 'components/shared/icons/24x24/CampaignsIcon24'
import ExpensesIcon24 from "components/shared/icons/24x24/ExpensesIcon24"

export const nav = [
  {
    title: 'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.STATISTICS',
    mobileTitle: 'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.MOBILE.STATISTICS',
    icon: <StatisticsIcon24 />,
    path: PATH_STATISTICS_DEFAULT,
    selectedPath: PATH_STATISTICS
  },
  {
    title: 'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.DOMAINS',
    mobileTitle: 'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.DOMAINS',
    icon: <DomainsIcon24 />,
    path: PATH_DOMAINS_ACTIVE_DEFAULT,
    selectedPath: PATH_DOMAINS
  },
  {
    title: 'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.KYES',
    mobileTitle: 'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.KYES',
    icon: <KeyIcon24 />,
    path: PATH_KEYS_DEFAULT,
    selectedPath: PATH_KEYS
  },
  {
    title: 'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.SHELLS',
    mobileTitle: 'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.SHELLS',
    icon: <ShellsIcon24 />,
    path: PATH_SHELLS_DEFAULT,
    selectedPath: PATH_SHELLS
  },
  {
    title: 'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.CAMPAIGNS',
    mobileTitle: 'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.CAMPAIGNS',
    icon: <CampaignsIcon24 />,
    path: PATH_CAMPAIGNS,
    selectedPath: PATH_CAMPAIGNS
  },
  {
    title: 'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.FINANCE',
    mobileTitle: 'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.FINANCE',
    icon: <ExpensesIcon24 />,
    path: PATH_EXPENSES,
    selectedPath: PATH_EXPENSES
  }
]
