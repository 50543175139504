import React, { FC, useEffect, useState } from 'react'
import PageLayout from 'components/PageLayout'
import { ExpensesData, ExpensesFetch } from 'types/expenses.types'
import {User} from "types/user.types"
import moment from 'moment'
import { Box, Button, Typography } from '@mui/material'
import {getFormattedDate} from "../../utils/shared.utils";
import {IntlShape, useIntl} from "react-intl";
interface ExpensesPageProps {
  expenses: ExpensesData
  fetchExpenses: ExpensesFetch
  user: User
}

const getWeekPeriods = () => {
  const today = moment()

  const currentWeekStart = today.clone().startOf('isoWeek')
  const currentWeekEnd = today.clone().endOf('isoWeek')

  const pastWeekStart = currentWeekStart.clone().subtract(1, 'week')
  const pastWeekEnd = currentWeekEnd.clone().subtract(1, 'week')

  return {
    past: {
      label: 'Минулий тиждень',
      from: pastWeekStart.format('YYYY-MM-DD'),
      to: pastWeekEnd.format('YYYY-MM-DD'),
    },
    current: {
      label: 'Поточний тиждень',
      from: currentWeekStart.format('YYYY-MM-DD'),
      to: currentWeekEnd.format('YYYY-MM-DD'),
    },
  }
}


const ExpensesPage: FC<ExpensesPageProps> = ({ expenses, fetchExpenses, user }) => {
  const intl: IntlShape = useIntl()
  const periods = getWeekPeriods()
  const [activeTab, setActiveTab] = useState<'past' | 'current'>('past')
  const userId = user.id
  const [loading, setLoading] = useState(true)


  const handleTabChange = (tab: 'current' | 'past') => {
    setActiveTab(tab)
    setLoading(true)

    const { from, to } = getWeekPeriods()[tab]
    fetchExpenses(userId, from, to).finally(() => setLoading(false))
  }


  useEffect(() => {
    const { from, to } = periods[activeTab]
    fetchExpenses(userId, from, to).finally(() => setLoading(false))
  }, [])

  const selectedPeriod = periods[activeTab]

  const cssTabs = {
    height: '48px',
    padding: '12px 24px',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: 'normal',
    textDecoration: 'none',
    marginLeft: '16px',
    borderColor: "transparent",
    "&:hover": {
      borderColor: "transparent",

    },
    "&.active": {
      border: 'solid 1px transparent',
      background: '#ffffff0a',
    },
  }

  return (
      <PageLayout title="EXPENSES_PAGE.TITLE">
        <Box display="flex" gap={2} mb={2} justifyContent="space-around">
          <Button sx={{ ...cssTabs }} className={activeTab === 'past' ? 'active' : ''}
              onClick={() => handleTabChange('past')}
          >
            {periods.past.label}
          </Button>
          <Button sx={{ ...cssTabs }} className={activeTab === 'current' ? 'active' : ''}
              onClick={() => handleTabChange('current')}
          >
            {periods.current.label}
          </Button>
        </Box>
        {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
              <video
                  src="/loader_min.mp4"
                  autoPlay
                  loop
                  muted
                  width={170}
              />
            </Box>
        ) : (
            <Box sx={{ background: 'rgba(39, 39, 42, 0.6)', p: 3, borderRadius: 4 }}>
              <Box display="flex" justifyContent="space-between" mb={3}>
                <Typography variant="body2" color="text.secondary" fontSize="12px" fontFamily="Inter">
                  Назва
                </Typography>
                <Typography variant="body2" color="text.secondary" fontSize="12px" fontFamily="Inter">
                  Сума
                </Typography>
              </Box>

              {expenses?.zone_list.map(zone => (
                  <Box
                      key={zone.name}
                      display="flex"
                      justifyContent="space-between"
                      mb={3}
                  >
                    <Typography fontSize="12px">
                      Доменів: {zone.number} .{zone.name.toUpperCase()} (${zone.cost})
                    </Typography>
                    <Typography fontSize="12px" fontFamily="Inter">${zone.spending}</Typography>
                  </Box>
              ))}

              <Box display="flex" justifyContent="space-between" mb={3}>
                <Typography fontSize="12px" fontFamily="Inter">Комісія 3% за повернення від Openprovider</Typography>
                <Typography fontSize="12px" fontFamily="Inter">${expenses?.spending.fee_openprovider}</Typography>
              </Box>

              <Box display="flex" justifyContent="space-between" mb={3}>
                <Typography fontSize="12px" fontFamily="Inter">Компенсація підписки в Openprovider</Typography>
                <Typography fontSize="12px" fontFamily="Inter">${expenses?.spending.sub_openprovider}</Typography>
              </Box>

              <Box display="flex" justifyContent="space-between" mt={5}>
                <Typography fontWeight="bold" fontFamily="Inter">Усього</Typography>
                <Typography fontWeight="bold" fontFamily="Inter">${expenses?.result}</Typography>
              </Box>

              <Box display="flex" justifyContent="space-between">
                <Typography mt={6} variant="caption" color="text.secondary" fontFamily="Inter" fontSize="12px">
                  Період: {getFormattedDate(selectedPeriod.from, intl)} - {getFormattedDate(selectedPeriod.to, intl)}

                </Typography>
              </Box>

            </Box>
        )}


      </PageLayout>
  )
}

export default ExpensesPage
