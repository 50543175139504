import React, {FC, useEffect, useState, memo } from 'react'
import {createSearchParams, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import { IntlShape, useIntl } from 'react-intl'
import moment from 'moment/moment'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import Table from 'components/shared/Table'
import Dialog from 'components/shared/Dialog'
import CopyButton from 'components/shared/CopyButton'
import TooltipHover from 'components/shared/TooltipHover'
import PlusIcon24 from 'components/shared/icons/24x24/PlusIcon24'
import AutomaticIcon16 from 'components/shared/icons/16x16/AutomaticIcon16'
import ManuallyIcon16 from 'components/shared/icons/16x16/ManuallyIcon16'
import ActiveIcon16 from 'components/shared/icons/16x16/ActiveIcon16'
import WarningIcon16 from 'components/shared/icons/16x16/WarningIcon16'
import DeleteIcon16 from 'components/shared/icons/16x16/DeleteIcon16'
import CopyIcon16 from 'components/shared/icons/16x16/CopyIcon16'
import CheckIcon16 from 'components/shared/icons/16x16/CheckIcon16'
import CheckMixIcon16 from 'components/shared/icons/16x16/CheckMixIcon16'
import FileIcon16 from 'components/shared/icons/16x16/FileIcon16'
import {getFormattedDate, getRGBAColor} from 'utils/shared.utils'
import { FetchShellsList, ShellsHash, ShellsList, ShellsTableHeadIds, UpdateShell } from 'types/shells.types'
import { Pagination, SortMethod } from 'types/shared.types'
import { PATH_SHELLS } from 'constants/routes.constants'
import {
  SHELLS_DEFAULT_PAGINATION_PER_PAGE,
  SHELLS_PAGINATION_OPTIONS,
  SHELLS_TABLE_HEAD
} from 'constants/shells.constants'
import styles from './ShellsTable.styles'
import Access0Icon16 from "components/shared/icons/16x16/Access0Icon16";
import Access1Icon16 from "components/shared/icons/16x16/Access1Icon16";
import FileAutoIcon16 from "components/shared/icons/16x16/FileAutoIcon16";
import {COMPONENT_COLOR_ICON_TERTIARY} from "constants/tokens";
import MoreIcon16 from "components/shared/icons/16x16/MoreIcon16";
import {ClickAwayListener, Paper, Popper} from "@mui/material";
import EditShellForm from 'components/shells/ShellsPage/EditShellForm';

interface ShellsTableParams {
  shellsList: ShellsList
  shellsListPagination: Pagination
  fetchShellsList: FetchShellsList
  updateShell: UpdateShell
}

const ShellsTable: FC<ShellsTableParams> = memo(({ shellsList, shellsListPagination, fetchShellsList, updateShell }) => {
  const intl: IntlShape = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { hash } = useLocation()

  const sortMethod = (searchParams.get('sortMethod') as SortMethod) || SortMethod.DESC
  const sortBy = (searchParams.get('sortBy') as ShellsTableHeadIds) || ShellsTableHeadIds.CREATED_AT
  const page = Number(searchParams.get('page')) || 1
  const perPage = Number(searchParams.get('perPage')) || SHELLS_DEFAULT_PAGINATION_PER_PAGE

  const [deletedId, setDeletedId] = useState<null | number>(null)

  const [selectedShellId, setSelectedShellId] = useState<number | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const selectedRowId = React.useRef<number | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>, id: number) => {
    if (anchorEl && selectedRowId.current === id) {
      // Якщо вже відкрито — закриваємо
      setAnchorEl(null)
      selectedRowId.current = null
    } else {
      // Відкриваємо для нового рядка
      setAnchorEl(event.currentTarget)
      selectedRowId.current = id
    }
  };

  const handleClose = () => {
    setAnchorEl(null)
    selectedRowId.current = null
  };


  const closeUpdateDialog = () => {
    setSelectedShellId(null)
    navigate({
      pathname: PATH_SHELLS,
      search: searchParams.toString()
    }, { replace: true })
  }

  const handleOpenDialog = (id: number) => {
    setSelectedShellId(id)
    navigate({
      pathname: PATH_SHELLS,
      search: searchParams.toString(),
      hash: ShellsHash.UPDATE
    })
  }

  const closeDialog = () => setDeletedId(null)

  const deleteShell = async (id: number) => {
    try {
      await updateShell({ status: false }, id)

      handleFetchShellsList()
    } catch (error) {
      console.log(error)
    }

    closeDialog()
  }

  const handleFetchShellsList = () => {
    fetchShellsList(sortMethod, sortBy, page, perPage)

    navigate({
      pathname: PATH_SHELLS,
      search: createSearchParams({ sortMethod, sortBy, page: String(page), perPage: String(perPage) }).toString()
    })
  }

  useEffect(() => {
    if (hash === ShellsHash.UPDATE && !selectedRowId.current) {
      closeUpdateDialog()
    }
  }, [hash])


  return (
    <Table
      sx={styles.table}
      sortByDefault={ShellsTableHeadIds.CREATED_AT}
      tableHead={SHELLS_TABLE_HEAD}
      fetchTableData={fetchShellsList}
      isTableData={shellsList.length > 0}
      emptyTitle="SHELLS_PAGE.SHELLS_TABLE.TEXT.EMPTY"
      emptyContent={
        <>
          <Button
            variant="contained"
            size="large"
            onClick={() =>
              navigate({
                pathname: PATH_SHELLS,
                search: searchParams.toString(),
                hash: ShellsHash.ADD
              })
            }
            startIcon={<PlusIcon24 />}
          >
            {intl.formatMessage({ id: 'SHELLS_PAGE.BUTTON.ADD_SHELL' })}
          </Button>
        </>
      }
      pagination={shellsListPagination}
      defaultPaginationPerPage={SHELLS_DEFAULT_PAGINATION_PER_PAGE}
      paginationOptions={SHELLS_PAGINATION_OPTIONS}
    >
      {shellsList.map((row, index) => {
        const diff = moment.utc().diff(moment.utc(row.lastRequestedAt), 'hours')
        const isWarning = diff >= 48 || (!row.fileStatus && !row.handStatus && row.lastRequestedAt === null)
        const opacityStyle = isWarning ? styles.opacity : {}

        return (
          <TableRow key={`${row.id}-${index}`} sx={isWarning ? styles.warning : {}}>
            <TableCell sx={opacityStyle}>{row.id}</TableCell>
            <TableCell sx={opacityStyle}>{row.farmId}</TableCell>
            <TableCell sx={opacityStyle}>{getFormattedDate(row.createdAt, intl)}</TableCell>
            <TableCell width={20} sx={opacityStyle}>{row.cost ? `$${row.cost}` : '-'}</TableCell>
            <TableCell>
              <Box display="flex" alignItems="center">
                <Box sx={opacityStyle}>{row.domain}</Box>
                <CopyButton value={row.domain}>
                  <IconButton className="copyBtn" sx={styles.copy} size="small" color="outlinedSecondary">
                    <CopyIcon16 />
                  </IconButton>
                </CopyButton>
              </Box>
            </TableCell>
            <TableCell sx={opacityStyle}>{row.system}</TableCell>

            <TableCell>
              {!row.accessStatus ? (
                  <Access0Icon16 sx={styles.secondaryIcon} />
              ) : (
                  <Access1Icon16 sx={styles.secondaryIcon} />
              )}
            </TableCell>

            <TableCell>
              {!row.handStatus ? (
                  <FileAutoIcon16 sx={styles.secondaryIcon} />
              ) : (
                  <ManuallyIcon16  sx={styles.secondaryIcon} />
              )}
            </TableCell>

            <TableCell>
              {row.handStatus ? (
                <Box display="flex" alignItems="center">
                  <FileIcon16 sx={{ mr: 1, ...styles.secondaryIcon }} />
                  index
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  {row.fileStatus ? <ActiveIcon16 sx={{ mr: 1 }} /> : <WarningIcon16 sx={{ mr: 1 }} />}
                  <Box sx={opacityStyle}>wp_stamp</Box>
                </Box>
              )}
            </TableCell>
            <TableCell>
              {row.cachedStatus ? (
                <CheckIcon16 sx={styles.secondaryIcon} />
              ) : (
                <CheckMixIcon16 sx={styles.secondaryIcon} />
              )}
            </TableCell>
            <TableCell sx={opacityStyle}>{row.requestsToday || '-'}</TableCell>
            <TableCell sx={opacityStyle}>{row.requestsYesterday || '-'}</TableCell>

            <TableCell sx={{ color: COMPONENT_COLOR_ICON_TERTIARY }}>{row.comments}</TableCell>
            <TableCell>

              <IconButton
                  size="small"
                  color="outlinedSecondary"
                  onClick={(event) => handleClick(event, row.id)}
              >
                <MoreIcon16 />
              </IconButton>


                <Popper     open={Boolean(anchorEl) && selectedRowId.current === row.id}
                            anchorEl={anchorEl}
                        placement="bottom-end" disablePortal={false} sx={{
                  m: 0, p:0, background: '#27272A', color: "white", borderRadius: '8px', border: 0, zIndex: 999
                }}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <Paper sx={{
                      borderRadius: '8px', border: 0, background: '#27272A'
                    }}>
                      <Box sx={{
                        background: '#27272A', borderRadius: '8px', border: 0,
                        display: "flex", flexDirection: "column"
                      }}>
                        <Button onClick={() => handleOpenDialog(row.id)} sx={{
                          background: '#27272A', color: "white", borderRadius: '8px 8px 0 0', display: "block",
                          "&:hover": {
                            background: '#ffffff12'
                          }
                        }} size="small" variant="text">
                          Редагувати
                        </Button>
                        <Button onClick={() => setDeletedId(row.id)} sx={{
                          background: '#27272A', color: "white", borderRadius: '0 0 8px 8px', display: "block",
                          "&:hover": {
                            backgroundColor: '#ffffff12'
                          }
                        }} size="small" variant="text">
                          Видалити
                        </Button>
                      </Box>

                    </Paper>
                  </ClickAwayListener>
                </Popper>
              <Dialog
                  open={deletedId === row.id}
                  onClose={closeDialog}
                  dialogTitle={intl.formatMessage({ id: 'SHELLS_PAGE.DIALOG.TITLE.DELETE_SHELL' })}
                  dialogActions={
                    <>
                      <Button onClick={closeDialog} variant="outlined" color="secondary" size="large">
                        {intl.formatMessage({ id: 'SHELLS_PAGE.DIALOG.BUTTON.CANCEL' })}
                      </Button>
                      <Button onClick={() => deleteShell(row.id)} variant="contained" color="error" size="large">
                        {intl.formatMessage({ id: 'SHELLS_PAGE.DIALOG.BUTTON.DELETE' })}
                      </Button>
                    </>
                  }
              >
                <Typography variant="LRegular" align="center">
                  {intl.formatMessage({ id: 'SHELLS_PAGE.DIALOG.TEXT.DELETE_SHELL' }, { domain: row.domain })}
                </Typography>
              </Dialog>
            </TableCell>
          </TableRow>
        )
      })}
      {selectedShellId && (
          <Dialog
              open={Boolean(selectedShellId)}
              onClose={closeUpdateDialog}
              dialogTitle={intl.formatMessage({ id: 'SHELLS_PAGE.DIALOG.TITLE.UPDATE_SHELL' })}
          >
            <EditShellForm shellId={selectedShellId} onSuccess={closeUpdateDialog}/>
          </Dialog>
      )}
    </Table>
  )
})

export default ShellsTable
